import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import MVDev from "../images/MVDev.svg"

const Web = () => {
  return (
    <div>
      <Layout>
        <WebWrapper>
          <h2>Création de site web</h2>
          <p>
            Pour toute demande de création de site web, nous vous invitons à
            vous rendre sur le site MVDev.fr en cliquant sur le logo ci dessous:
          </p>
          <a href="https://www.mvdev.fr">
            <img alt="MVDev.fr" src={MVDev} />
          </a>
        </WebWrapper>
      </Layout>
    </div>
  )
}

const WebWrapper = styled.div`
  width: 90%;
  margin: 3% auto;
  text-align center;
  img{
    margin: -10% 0 0 0;
  }
`

export default Web
